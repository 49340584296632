import React from "react";
import Seo from "../../../components/seo";
import Industry from "../../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../../components/layout";
import {Glasses} from '../../../components/svgs/industries';

const EyewearOpticsIndustryPageEn = () => {
  const data = useStaticQuery(graphql`
    query EyewearOpticsIndustryDatasEn {
      allContentfulSecteurs(filter: {contentful_id: {eq: "PWF1ScawoaaxnKIgjOtuc"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[0].node.permalink} 
    lang="en-CA"
    title={data.allContentfulSecteurs.edges[1].node.name} 
    subTitle="Digital signage in"
    icon={<Glasses/>}>
      <Seo title="Indoor Digital Signage Advertising for Eyewear Optics"
           description="Educate and inform your customers, promote your services, decrease the impression of long waiting period with our indoor digital signage advertising."
           lang="en-CA"/>
      <Industry lang="en-CA" datas={data.allContentfulSecteurs.edges[1].node}/>
    </Layout>
  )
}

export default EyewearOpticsIndustryPageEn
